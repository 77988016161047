<template>
	<div class="flex flex-row items-center justify-center space-x-2">
		<div class="animate-spin inline-block w-16 h-16 rounded-full" role="status">
			<span class="sr-only">Loading...</span>
			<img alt="Conduction Logo" class="h-full w-full" src="/logo.svg" />
		</div>
		<div class="font-semibold text-4xl px-5 anim">Loading...</div>
	</div>
</template>

<script lang="ts" setup></script>

<style scoped></style>
